@charset "UTF-8";
/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Backgrounds
----------------------------------------------------------------------------- */
.bg-theme-primary {
  background: #0a4063;
}

/* ----------------------------------------------------------------------------
    Text
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: #0a4063 !important;
}

.text-theme-secondary {
  color: #044fba !important;
}

.text-theme-alt {
  color: #3fbce1 !important;
}

.text-headline-primary {
  color: #044fba !important;
}

.text-headline-secondary {
  color: #0a4063 !important;
}

.text-theme-danger {
  color: #880303 !important;
}

.border-theme-secondary {
  border-color: #044fba !important;
}

.border-theme-alt {
  border-color: #3fbce1 !important;
}

/* ----------------------------------------------------------------------------
Font Icons
----------------------------------------------------------------------------- */
@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot?21099076");
  src: url("../fonts/icons.eot?21099076#iefix") format("embedded-opentype"), url("../fonts/icons.woff2?21099076") format("woff2"), url("../fonts/icons.woff?21099076") format("woff"), url("../fonts/icons.ttf?21099076") format("truetype"), url("../fonts/icons.svg?21099076#icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^=icon-]:before,
[class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-dribbble:before {
  content: "\e801";
}

.icon-instagram:before {
  content: "\e802";
}

.icon-behance:before {
  content: "\e803";
}

.icon-linkedin:before {
  content: "\e804";
}

.icon-facebook:before {
  content: "\e805";
}

.icon-twitter:before {
  content: "\e806";
}

.icon-gplus:before {
  content: "\e807";
}

.icon-pinterest:before {
  content: "\e808";
}

.icon-tumblr:before {
  content: "\e809";
}

.icon-down:before {
  content: "\e810";
}

.icon-left:before {
  content: "\e811";
}

.icon-right:before {
  content: "\e812";
}

.icon-up:before {
  content: "\e813";
}

.icon-left-small:before {
  content: "\e814";
}

.icon-right-small:before {
  content: "\e815";
}

.icon-up-small:before {
  content: "\e816";
}

.icon-down-small:before {
  content: "\e817";
}

.icon-down-thin:before {
  content: "\e818";
}

.icon-left-thin:before {
  content: "\e819";
}

.icon-right-thin:before {
  content: "\e820";
}

.icon-up-thin:before {
  content: "\e821";
}

.icon-down-arrow:before {
  content: "\e822";
}

.icon-left-arrow:before {
  content: "\e823";
}

.icon-right-arrow:before {
  content: "\e824";
}

.icon-up-arrow:before {
  content: "\e825";
}

.icon-menu:before {
  content: "\e826";
}

.icon-home:before {
  content: "\e827";
}

.icon-lock:before {
  content: "\e828";
}

.icon-phone:before {
  content: "\e829";
}

.icon-chat:before {
  content: "\e830";
}

.icon-mail:before {
  content: "\e831";
}

.icon-comment:before {
  content: "\e832";
}

.icon-at:before {
  content: "\e833";
}

.icon-star:before {
  content: "\e834";
}

.icon-search:before {
  content: "\e835";
}

.icon-zoom:before {
  content: "\e836";
}

.icon-cog:before {
  content: "\e837";
}

.icon-link:before {
  content: "\e838";
}

.icon-share:before {
  content: "\e839";
}

.icon-check:before {
  content: "\e840";
}

.icon-cancel:before {
  content: "\e841";
}

.icon-plus:before {
  content: "\e842";
}

.icon-minus:before {
  content: "\e843";
}

.icon-close:before {
  content: "\e844";
}

.icon-user:before {
  content: "\e845";
}

.icon-spinner:before {
  content: "\e846";
}

.icon-youtube:before {
  content: "\e847";
}

a.social {
  display: inline-block;
  height: 50px;
  width: 50px;
  margin: 5px;
  color: #fff !important;
  background-color: #ccc;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 22px;
  text-align: center;
  overflow: hidden;
  vertical-align: middle;
  border: 0 !important;
  border-radius: 30px;
  transition: all 0.15s linear 0s;
}

a.social [class^=icon-]:before {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 50px;
}

a.social:hover,
a.social:active {
  font-size: 24px;
  border-radius: 4px;
  /*transform: rotate(360deg);*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none !important;
}

a.social.dribbble:hover,
a.social.dribbble:active {
  background-color: #ea4c89;
}

a.social.instagram:hover,
a.social.instagram:active {
  background-color: #49789d;
}

a.social.behance:hover,
a.social.behance:active {
  background-color: #1769ff;
}

a.social.facebook:hover,
a.social.facebook:active {
  background-color: #3f5495;
}

a.social.twitter:hover,
a.social.twitter:active {
  background-color: #5d87c4;
}

a.social.linkedin:hover,
a.social.linkedin:active {
  background-color: #2575b2;
}

a.social.gplus:hover,
a.social.gplus:active {
  background-color: #cc4436;
}

a.social.pinterest:hover,
a.social.pinterest:active {
  background-color: #c2142f;
}

a.social.tumblr:hover,
a.social.tumblr:active {
  background-color: #529ecc;
}

a.social.youtube:hover,
a.social.youtube:active {
  background-color: #ff0000;
}

/* open-sans-300 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-300.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-300.woff") format("woff"), url("../fonts/open-sans-v18-latin-300.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-300.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-regular.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-regular.woff") format("woff"), url("../fonts/open-sans-v18-latin-regular.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600.woff") format("woff"), url("../fonts/open-sans-v18-latin-600.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-600italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700.woff") format("woff"), url("../fonts/open-sans-v18-latin-700.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-700italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* fjalla-one-regular - latin */
@font-face {
  font-family: "Fjalla One";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/fjalla-one-v8-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/fjalla-one-v8-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/fjalla-one-v8-latin-regular.woff2") format("woff2"), url("../fonts/fjalla-one-v8-latin-regular.woff") format("woff"), url("../fonts/fjalla-one-v8-latin-regular.ttf") format("truetype"), url("../fonts/fjalla-one-v8-latin-regular.svg#FjallaOne") format("svg"); /* Legacy iOS */
}
/* roboto-slab-regular - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-regular.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-regular.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-regular.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-regular.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* roboto-slab-600 - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-600.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-600.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-600.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-600.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* roboto-slab-700 - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-700.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-700.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-700.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-700.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* montserrat-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/montserrat-v25-latin-300.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-300.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/montserrat-v25-latin-300italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-300italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserrat-v25-latin-regular.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-regular.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/montserrat-v25-latin-italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/montserrat-v25-latin-500.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-500.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/montserrat-v25-latin-500italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-500italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/montserrat-v25-latin-600.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-600.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/montserrat-v25-latin-600italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-600italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/montserrat-v25-latin-700.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-700.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/montserrat-v25-latin-700italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-700italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/montserrat-v25-latin-800.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-800.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* material icons */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIcons-Regular.woff2") format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* fallback */
@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIconsOutlined-Regular.otf") format("woff2");
}
.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* fallback */
@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIconsRound-Regular.otf") format("woff2");
}
.material-icons-round {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* ----------------------------------------------------------------------------
    CSS Variable, needs to be set in :root
----------------------------------------------------------------------------- */
:root {
  --font-family:
    "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* ----------------------------------------------------------------------------
    Smooth Scrolling
----------------------------------------------------------------------------- */
@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
[id] {
  scroll-margin-top: 1rem;
}

/* ----------------------------------------------------------------------------
    Typography
----------------------------------------------------------------------------- */
body {
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600;
  font-family: var(--font-family);
  line-height: 1.4;
  color: #044fba;
}

h1,
.h1 {
  font-size: 1.602em;
}

h2,
.h2 {
  font-size: 1.424em;
}

h3,
.h3 {
  font-size: 1.266em;
}

h4,
.h4 {
  font-size: 1.125em;
}

h5,
.h5 {
  font-size: 1em;
}

@media (min-width: 48em) {
  /* Medium devices (tablets, 768px and up) */
  h1,
  .h1 {
    font-size: 1.8em;
  }
  h2,
  .h2 {
    font-size: 1.55em;
  }
  h3,
  .h3 {
    font-size: 1.32em;
  }
  h4,
  .h4 {
    font-size: 1.125em;
  }
  h5,
  .h5 {
    font-size: 1em;
  }
}
@media (min-width: 62em) {
  /* Large devices (desktops, 992px and up) */
  h1,
  .h1 {
    font-size: 2.074em;
  }
  h2,
  .h2 {
    font-size: 1.728em;
  }
  h3,
  .h3 {
    font-size: 1.44em;
  }
  h4,
  .h4 {
    font-size: 1.2em;
  }
  h5,
  .h5 {
    font-size: 1.1em;
  }
}
@media (min-width: 75em) {
  /* Extra large devices (large desktops, 1200px and up) */
  h1,
  .h1 {
    font-size: 2.441em;
  }
  h2,
  .h2 {
    font-size: 1.953em;
  }
  h3,
  .h3 {
    font-size: 1.563em;
  }
  h4,
  .h4 {
    font-size: 1.25em;
  }
  h5,
  .h5 {
    font-size: 1.1em;
  }
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

a {
  color: #044fba;
  text-decoration: none;
  outline: none !important;
}

a:hover,
a:active {
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #0564ec;
}

a.img:hover,
a.img:active {
  text-decoration: none;
  border: 0;
}

a[href^="tel:"] {
  color: inherit;
  border: 0;
}

ul.nav,
ol.nav {
  margin-left: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}

.cfix:after,
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

#content,
#main_content,
.container,
.container-fluid,
.break_word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/* ----------------------------------------------------------------------------
    Forms Elements
----------------------------------------------------------------------------- */
.opacity-1 {
  opacity: 1 !important;
}

.display-none {
  display: none !important;
}

/* ----------------------------------------------------------------------------
    Buttons
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Tables
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Modals
----------------------------------------------------------------------------- */
body.modal-open {
  overflow: auto !important;
  padding: 0 !important;
}

.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.modal-header .close {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  margin-top: -0.7rem;
}

.modal-body .btn-close {
  opacity: 1;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  z-index: 100;
  background-color: #ffffff;
  border-bottom-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

/* ----------------------------------------------------------------------------
  Dropdown List
----------------------------------------------------------------------------- */
.dropdown-list {
  margin: auto;
  width: auto;
  position: relative;
  box-sizing: border-box;
}
.dropdown-list span.dropdown {
  display: block;
  margin: 0;
  padding: 0 10px;
  width: auto;
  height: 40px;
  line-height: 38px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #a1c4e5;
  cursor: pointer;
  overflow: hidden;
}
.dropdown-list span.dropdown.is-active {
  border-radius: 3px 3px 0 0;
}
.dropdown-list span.dropdown:hover, .dropdown-list span.dropdown:active {
  background: #f1f1f1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.dropdown-list span.dropdown:after {
  float: right;
  margin-top: 0;
  margin-left: 10px;
  width: 15px;
  height: 100%;
  content: "\e817";
  color: ineherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}
.dropdown-list span.dropdown.is-active:after {
  content: "\e816";
}
.dropdown-list a:hover {
  text-decoration: none;
  border-bottom: 0;
}
.dropdown-list .drop {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  float: left;
  width: 100%;
  max-height: 0;
  position: absolute;
  background: #f9f9f9;
  top: 40px;
  border-radius: 0 0 3px 3px;
  z-index: 10;
  overflow-y: auto;
  opacity: 0;
  transition: max-height 0.3s cubic-bezier(0, 0.6, 0, 1), opacity 0.3s cubic-bezier(0, 0.6, 0, 1);
}
.dropdown-list .drop li {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  border: 0;
  height: auto;
  border-top: 1px dotted #ccc;
}
.dropdown-list .drop li:first-child {
  border-top: 0;
}
.dropdown-list .drop li span.submenu.level-2 {
  display: block;
  margin: 0;
  padding: 6px 30px 6px 17px;
  position: relative;
  font-weight: 600;
}
.dropdown-list .drop li span.submenu.level-2:after {
  display: block;
  width: 20px;
  content: "\e817";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: 105%;
  position: absolute;
  right: 10px;
  top: 5px;
}
.dropdown-list .drop li span.submenu.level-2.is-active:after {
  content: "\e816";
}
.dropdown-list .drop li span.submenu.level-2.is-active, .dropdown-list .drop li span.submenu.level-2:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}
.dropdown-list .drop li span.submenu.level-2.is-active + ul.submenu {
  max-height: 500px;
  background-color: #ffffff;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.dropdown-list .drop li ul.submenu {
  padding-left: 0;
  max-height: 0;
  overflow-y: auto;
  transition: max-height 0.3s cubic-bezier(0, 0.6, 0, 1), opacity 0.3s cubic-bezier(0, 0.6, 0, 1);
}
.dropdown-list .drop li ul.submenu li:first-child {
  border-top: 1px dotted #ccc;
}
.dropdown-list .drop li ul.submenu li a {
  padding-left: 38px;
  font-weight: normal;
}
.dropdown-list .drop li ul.submenu li a:hover {
  background-color: #fbfbfb;
  padding-left: 43px;
}
.dropdown-list .drop li a {
  float: none;
  display: block;
  margin: 0;
  padding: 6px 10px 6px 30px;
  height: auto;
  width: auto;
  text-transform: none;
  font-weight: 600;
  color: inherit;
  text-align: left;
  border-radius: 0;
  border: 0;
  transition: padding-left 0.25s cubic-bezier(0, 0.7, 0, 1);
}
.dropdown-list .drop li a:hover, .dropdown-list .drop li a:active {
  padding-left: 35px;
  background: #f1f1f1;
}
.dropdown-list .drop li a:before {
  display: inline-block;
  margin-left: -15px;
  margin-right: 5px;
  width: 10px;
  height: inherit;
  content: "»";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}
.dropdown-list span.is-active + .drop {
  max-height: 350px;
  border: 1px solid #a1c4e5;
  border-top: 0;
  opacity: 1;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.dropdown-list.dropdown-cta {
  display: inline-block;
  width: auto;
}
.dropdown-list.dropdown-cta span.dropdown {
  padding: 0.5rem 1rem;
  display: inline-block;
  width: auto;
  height: auto;
  background-color: #044fba;
  border-color: #044fba;
  border-radius: 0.3rem;
  font-family: var(--font-family);
  line-height: 1.5;
  text-align: left;
}
.dropdown-list.dropdown-cta span.dropdown:after {
  font-weight: 400;
}
.dropdown-list.dropdown-cta span.dropdown:hover {
  background-color: #055ad3;
  border-color: #0564ec;
}
.dropdown-list.dropdown-cta span.dropdown.btn-outline-secondary {
  background-color: transparent;
  border-color: #044fba;
}
.dropdown-list.dropdown-cta span.dropdown.btn-outline-secondary:hover, .dropdown-list.dropdown-cta span.dropdown.btn-outline-secondary.is-active {
  color: #fff;
  background-color: #055ad3;
  border-color: #0564ec;
}
.dropdown-list.dropdown-cta .drop {
  top: calc(100% - 3px);
  max-width: 370px;
  width: calc(100vw - 30px);
  border-radius: 0 3px 3px 3px;
}
.dropdown-list.dropdown-cta span.is-active + .drop {
  max-height: 450px;
  border: 1px solid #044fba;
}

ul,
ol {
  padding-left: 30px;
}

ul li,
ol li {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

ul.check,
ul.star,
ul.carrot {
  margin-left: 0;
  padding-left: 0;
  list-style: none outside;
  line-height: inherit;
}

ul.check li,
ul.star li,
ul.carrot li {
  padding-left: 1.3em;
  margin-left: 0;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  position: relative;
}

ul.carrot li {
  padding-left: 0.9em;
}

ul.check > li:before,
ul.star > li:before,
ul.carrot > li:before {
  display: block;
  margin-top: 0.1em;
  width: 1em;
  height: inherit;
  color: inherit;
  font-size: 0.9em;
  font-weight: normal !important;
  word-wrap: normal;
  position: absolute;
  left: 0;
}

ul.check > li:before {
  content: "\e840";
  font-family: "icons";
}

ul.star > li:before {
  content: "\e834";
  font-family: "icons";
}

ul.carrot > li:before {
  content: "»";
  font-family: "icons";
  margin-top: 0.2em;
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  width: 1em;
}

/* ----------------------------------------------------------------------------
  Accordion
----------------------------------------------------------------------------- */
.accordion-wrap {
  margin-bottom: 1rem;
}

.accordion {
  padding: 0;
  border-bottom: 1px dotted #ccc;
  border-left: 1px dotted #ccc;
  border-right: 1px dotted #ccc;
  overflow: hidden;
}
.accordion:first-child {
  border-top: 1px dotted #ccc;
}
.accordion.bookstore {
  border: 0;
  margin-bottom: 5px;
}
.accordion.bookstore .accordion-toggle {
  background-color: #0a4063;
  color: #fff;
  border-radius: 0.25rem;
}
.accordion.bookstore .accordion-toggle:hover {
  background-color: #044fba;
}
.accordion.bookstore.is-open > .accordion-toggle {
  background-color: #044fba;
  margin-bottom: 1.5rem;
}
.accordion .accordion-toggle {
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 1rem;
  padding-right: calc(1em + 1rem);
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  color: inherit;
  overflow: hidden;
  transition: all ease-in-out 0.1s;
}
.accordion .accordion-toggle:before {
  float: right;
  color: inherit;
  content: "\e817";
  font-family: "icons";
  font-size: 1em;
  font-weight: normal !important;
  height: inherit;
  width: 1em;
  margin-right: -1em;
  text-align: right;
}
.accordion .accordion-toggle:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.accordion .accordion-content {
  padding: 0 1rem;
  height: 0;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.2s ease-in-out, height 0.3s cubic-bezier(0, 0.6, 0, 1), opacity 0.3s cubic-bezier(0, 0.6, 0, 1);
  overflow: hidden;
}
.accordion .accordion-content .accordion {
  border-left: 0;
  border-right: 0;
}
.accordion .accordion-content .accordion .accordion-toggle {
  padding-left: 0.75rem;
  padding-right: calc(1em + 0.75rem);
}
.accordion .accordion-content .accordion.is-open > .accordion-toggle {
  background-color: rgba(0, 0, 0, 0.02);
  color: #444 !important;
  margin-bottom: 0.75rem;
}
.accordion.is-open > .accordion-content {
  height: auto;
  opacity: 1;
  transform: scaleY(1);
  transition: transform 0.1s ease-in-out, height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.accordion.is-open > .accordion-toggle {
  background-color: rgba(0, 0, 0, 0.02);
  background-color: #0a4063;
  color: #fff !important;
  margin-bottom: 1rem;
}
.accordion.is-open > .accordion-toggle:before {
  content: "\e816";
}

/* ----------------------------------------------------------------------------
  Bootstrap Overrides
----------------------------------------------------------------------------- */
b,
strong {
  font-weight: 600;
}

.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.modal-header h1,
.modal-header h2,
.modal-header h3,
.modal-header h4,
.modal-header h5 {
  margin-top: 0;
  font-weight: 400;
}

input[type=number] {
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::placeholder {
  color: #cccccc !important;
}

.custom-control.is-invalid .custom-control-input ~ .custom-control-label, .custom-control:invalid .custom-control-input ~ .custom-control-label {
  color: #fb4f16;
}
.custom-control.is-invalid .custom-control-input ~ .custom-control-label:before, .custom-control:invalid .custom-control-input ~ .custom-control-label:before {
  border-color: #fb4f16;
}
.custom-control.is-invalid .custom-control-input:focus ~ .custom-control-label:before, .custom-control:invalid .custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-control.custom-checkbox {
  padding-left: 1.75rem;
}
.custom-control.custom-checkbox .custom-control-label:before, .custom-control.custom-checkbox .custom-control-label:after {
  left: -1.75rem;
  width: 1.2rem;
  height: 1.2rem;
}
.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #316ce8;
  background-color: #316ce8;
}
.custom-control.custom-radio {
  padding-left: 1.75rem;
}
.custom-control.custom-radio .custom-control-label:before, .custom-control.custom-radio .custom-control-label:after {
  left: -1.75rem;
  width: 1.2rem;
  height: 1.2rem;
}
.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #316ce8;
  background-color: #316ce8;
}

.form-control.is-invalid {
  border-color: #fb4f16;
}
.form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-image: none;
}

.custom-select.is-invalid {
  border-color: #fb4f16;
}
.custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
}

.table {
  word-wrap: normal;
  word-break: normal;
}
.table.align-middle th,
.table.align-middle td {
  vertical-align: middle;
}
.table.table-sm td,
.table.table-sm th {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.list-group-item {
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}
/* ----------------------------------------------------------------------------
  ASC Default stuff
----------------------------------------------------------------------------- */
.price {
  display: inline-block;
  padding: 0;
  width: auto;
  height: auto;
  color: #555;
  text-align: center;
  font: bold 0 "Trebuchet MS", "Droid Serif", Arial, sans-serif;
  overflow: visible;
  text-shadow: 0 0 40px #333;
  vertical-align: middle;
  color: transparent;
}

.price.static {
  color: #555;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
}

.price.shown {
  color: #555;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s;
}

.price.ribbon {
  margin-top: -6px;
  margin-bottom: 0;
  padding: 28px 0 0 0;
  width: 140px;
  height: 93px;
  color: #fff;
  background: url(../images/price_bg.png) no-repeat 0 0;
  text-shadow: 0 0 25px #fff;
  color: transparent;
}

.price.burst {
  padding: 60px 0 0 0;
  width: 155px;
  height: 155px;
  color: #fff;
  background: url(../images/price_bg_burst.png) no-repeat 50% 50%;
  text-shadow: 0 0 25px #fff;
  color: transparent;
}

.price.ribbon.static,
.price.burst.static {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.price.ribbon.shown,
.price.burst.shown {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s;
}

.price span.dollar-sign,
.price span.cents,
.price span.cents-fees,
.price span.only {
  display: inline-block;
  margin: 5px 0 0 0;
  font-size: 30px;
  line-height: 30px;
  vertical-align: top;
}

.price span.only {
  margin-bottom: 5px;
  font-size: 26px;
  display: block;
}

.price span.dollars,
.price span.dollars-fees {
  display: inline-block;
  margin-left: -1px;
  font-size: 50px;
  line-height: 50px;
  letter-spacing: -1px;
  vertical-align: middle;
}

.price span.cents,
.price span.cents-fees {
  margin: 4px 0 0 3px;
  letter-spacing: -1px;
}

.price.shown span.reg-price {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  text-decoration: line-through;
  padding-left: 3px;
  vertical-align: middle;
  text-shadow: none;
}

.price.ribbon span.reg-price {
  color: #eee;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.price span.dollars[data-pid]:after,
.price span.dollars-fees[data-pid]:after,
.price span.cents[data-pid]:after,
.price span.cents-fees[data-pid]:after,
.price span.dollars[data-pkgid]:after,
.price span.cents[data-pkgid]:after {
  display: inline-block;
  content: "##";
}

.price.shown span.dollars[data-pid]:after,
.price.shown span.dollars-fees[data-pid]:after,
.price.shown span.cents[data-pid]:after,
.price.shown span.cents-fees[data-pid]:after,
.price.shown span.dollars[data-pkgid]:after,
.price.shown span.cents[data-pkgid]:after {
  display: none;
}

/* -- CTI price display-- */
.price-display {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.2rem;
  font-weight: 600;
}
.price-display.regular-price {
  position: relative;
  opacity: 0.7;
  font-size: 0.95em;
}
.price-display.regular-price:after {
  display: block;
  content: "";
  width: calc(100% + 6px);
  height: 2px;
  background: #444;
  position: absolute;
  top: 54%;
  left: -3px;
  transform: rotate(-8deg);
  transform-origin: 40% 0;
}

/* -- Customize Bootstrap Utilities-- */
.w-auto {
  max-width: 100% !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: 100% !important;
  }
  .w-sm-5 {
    width: 5% !important;
  }
  .w-sm-10 {
    width: 10% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .w-md-auto {
    width: 100% !important;
  }
  .w-md-5 {
    width: 5% !important;
  }
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .w-lg-auto {
    width: 100% !important;
  }
  .w-lg-5 {
    width: 5% !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-auto {
    width: 100% !important;
  }
  .w-xl-5 {
    width: 5% !important;
  }
  .w-xl-10 {
    width: 10% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
}
@media (min-width: 1400px) {
  .w-xxl-auto {
    width: 100% !important;
  }
  .w-xxl-5 {
    width: 5% !important;
  }
  .w-xxl-10 {
    width: 10% !important;
  }
  .w-xxl-20 {
    width: 20% !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-30 {
    width: 30% !important;
  }
  .w-xxl-40 {
    width: 40% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-60 {
    width: 60% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-80 {
    width: 80% !important;
  }
  .w-xxl-90 {
    width: 90% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.ls-10 {
  letter-spacing: 0.1rem;
}

.ls-15 {
  letter-spacing: 0.15rem;
}

.ls-20 {
  letter-spacing: 0.2rem;
}

.ls-25 {
  letter-spacing: 0.25rem;
}

.lh-1 {
  line-height: 1;
}

.lh-12 {
  line-height: 1.2;
}

.lh-14 {
  line-height: 1.4;
}

.lh-16 {
  line-height: 1.6;
}

.lh-18 {
  line-height: 1.8;
}

.lh-20 {
  line-height: 2;
}

.lh-25 {
  line-height: 2.5;
}

/* ----------------------------------------------------------------------------
  Navigation Menu
----------------------------------------------------------------------------- */
#menutoggle {
  display: block;
  margin: 0;
  padding: 0 22px;
  width: auto;
  color: #fff;
  background: #0a4063;
  height: 45px;
  font-size: 18px;
  line-height: 46px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.35);
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 4px 4px 0 0;
  transition: background 0.2s linear 0s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
#menutoggle span {
  display: none;
}
#menutoggle::before {
  display: inline-block;
  margin-left: 0;
  width: 18px;
  height: 45px;
  line-height: 48px;
  content: "\e826";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: 21px;
  vertical-align: top;
}
#menutoggle.is-active:before {
  content: "\e844";
}
#menutoggle:hover, #menutoggle:active, #menutoggle.is-active {
  background-color: #0e5686;
  border-radius: 4px 4px 0 0;
}
#menutoggle.is-active + ul {
  margin-bottom: 20px;
  max-height: 4000px;
  opacity: 1;
  transition: max-height 0.3s ease-in-out, opacity 0.25s ease-in-out;
}

#nav {
  clear: both;
  margin: 0;
  width: 100%;
  height: 0;
  overflow: visible;
  position: relative;
  z-index: 1000;
}
#nav ul {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  width: auto;
  opacity: 0;
  max-height: 0;
  background-color: #0a4063;
  overflow: hidden;
  transition: max-height 0.25s cubic-bezier(0, 0.7, 0, 1), opacity 0.2s ease-in-out;
  border-radius: 0 0 4px 4px;
  top: 1px;
  border: 0;
  position: relative;
}
#nav ul.submenu {
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 0;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 1;
  position: relative;
  top: 0;
  transition: max-height 0.15s linear 0s;
  border-radius: 0;
}
#nav ul.submenu a {
  padding-left: 40px;
  background: none;
  color: #333;
  text-shadow: none;
  transition: padding-left 0.25s cubic-bezier(0, 0.7, 0, 1);
}
#nav ul.submenu a:before {
  display: inline-block;
  margin-right: 5px;
  margin-left: -15px;
  width: 10px;
  height: inherit;
  content: "»";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}
#nav ul.submenu a:hover {
  padding-left: 45px;
  background-color: #4caeed;
}
#nav ul.submenu span.submenu.level-2 {
  padding-left: 27px;
  display: block;
  color: #333;
  font-weight: 600;
  text-shadow: none;
}
#nav ul.submenu span.submenu.level-2:hover {
  background-color: #4caeed;
}
#nav ul.submenu span.submenu.level-2.is-active {
  background-color: #4caeed;
  border-bottom: 1px dotted #137cc0;
}
#nav ul.submenu ul.submenu {
  border-radius: 0;
}
#nav ul.submenu ul.submenu li a {
  padding-left: 45px;
  transition: padding-left 0.25s cubic-bezier(0, 0.7, 0, 1);
  color: #333;
  text-shadow: none;
}
#nav ul.submenu ul.submenu li a.is-active {
  background-color: transparent;
}
#nav ul.submenu ul.submenu li a:hover {
  padding-left: 50px;
  background-color: #c0e2f9;
}
#nav ul.submenu.is-active {
  background-color: #0e5686;
  border-bottom: 1px dotted #137cc0;
}
#nav li {
  display: block;
  margin: 0;
  padding: 0;
  height: auto;
  overflow: hidden;
  border: 0;
  border-top: 1px dotted #137cc0;
  position: relative;
}
#nav li:first-child {
  border-top: 0;
}
#nav li.onmobile {
  display: block;
}
#nav li.language-select {
  padding: 12px 20px;
}
#nav a, #nav span.submenu {
  float: none;
  display: block;
  margin: 0;
  padding: 9px 20px;
  height: auto;
  color: #fff;
  line-height: 1.6em;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  text-align: left;
  text-decoration: none;
  font-size: 16px;
  font-weight: normal;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: background 0.17s linear 0s;
}
#nav a.login:before, #nav a.cart:before, #nav span.submenu.login:before, #nav span.submenu.cart:before {
  display: inline-block;
  margin-right: 5px;
  color: inherit;
  content: "\ea77";
  font-family: "Material Icons Round";
  font-weight: normal;
  font-size: 110%;
  vertical-align: top;
  width: 23px;
  text-align: left;
}
#nav a.cart:before, #nav span.submenu.cart:before {
  content: "\e8cc";
  font-family: "Material Icons Outlined";
}
#nav span.submenu:after, #nav a.submenu:after {
  float: right;
  margin-left: 10px;
  width: 15px;
  height: inherit;
  content: "\e817";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#nav span.submenu.is-active, #nav a.submenu.is-active {
  border-bottom: 1px dotted #0e5686;
}
#nav span.submenu.is-active + ul.submenu, #nav a.submenu.is-active + ul.submenu {
  max-height: 2000px;
  overflow-y: auto;
}
#nav span.submenu.is-active:after, #nav a.submenu.is-active:after {
  content: "\e816";
}
#nav a:hover,
#nav a.is-active,
#nav li:hover a.submenu,
#nav ul.submenu a.is-active {
  background: #0e5686;
}

@media (min-width: 36em) { /* Small devices (landscape phones, 576px and up) */
  /*@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
  #menutoggle {
    padding: 0 15px 0 15px;
  }
  #menutoggle span {
    display: inline-block;
  }
  #menutoggle:before {
    margin-right: 10px;
  }
}
@media (min-width: 62em) { /* Large devices (desktops, 992px and up) */
  #nav-wrap {
    background: #0a4063;
  }
  #menutoggle {
    display: none;
  }
  #nav {
    display: flex;
    height: auto;
    background: #0a4063;
    position: relative;
    z-index: 99;
    border: 0;
    border-radius: 4px;
  }
  #nav ul {
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    max-height: 100%;
    opacity: 1;
    margin: 0;
    position: relative;
    z-index: 10;
    top: 0;
    overflow: visible;
  }
  #nav ul.submenu {
    display: block;
    float: left;
    margin: 0;
    padding: 0;
    width: 350px;
    max-height: 0;
    position: absolute;
    opacity: 0;
    background-color: #0e5686;
    top: 95%;
    z-index: 10;
    border-radius: 0 3px 3px 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    transition: max-height 0.2s linear 0s, padding 0.2s linear 0s, opacity 0.2s linear 0s;
    overflow: hidden;
    overflow-y: auto;
  }
  #nav ul.submenu.left {
    right: 0;
    left: auto !important;
    border-radius: 3px 0 3px 3px;
  }
  #nav ul.submenu li {
    float: none;
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    height: auto;
  }
  #nav ul.submenu li:first-child a {
    border-top: 0;
  }
  #nav ul.submenu a {
    float: none;
    display: block;
    margin: 0;
    padding: 7px 15px 7px 33px;
    height: auto;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    text-transform: none;
    text-align: left;
    line-height: 1.5em;
    border-radius: 0;
    border: 0;
    border-bottom: 1px dotted #137cc0;
    transition: padding-left 0.25s cubic-bezier(0, 0.7, 0, 1);
  }
  #nav ul.submenu a:hover {
    padding-left: 38px !important;
  }
  #nav ul.submenu span.submenu.level-2 {
    padding: 7px 15px 7px 20px;
    height: auto;
    display: block;
    text-align: left;
    position: relative;
    font-weight: normal;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    border-bottom: 1px dotted #137cc0;
    transition: padding-left 0.25s cubic-bezier(0, 0.7, 0, 1);
  }
  #nav ul.submenu span.submenu.level-2:hover {
    background-color: #0c4a73;
  }
  #nav ul.submenu span.submenu.level-2.is-active {
    background-color: #0c4a73;
  }
  #nav ul.submenu span.submenu.level-2.is-active + ul.submenu {
    max-height: 350px !important;
    overflow-y: auto;
  }
  #nav ul.submenu span.submenu.level-2.is-active:after {
    content: "\e816";
  }
  #nav ul.submenu span.submenu.level-2:after {
    display: block;
    float: right;
    margin-left: 10px;
    width: 15px;
    height: inherit;
    content: "\e817";
    color: inherit;
    font-family: "icons";
    text-align: center;
    font-size: 105%;
  }
  #nav ul.submenu ul.submenu {
    float: none;
    display: block;
    position: relative;
    padding: 0 !important;
    box-shadow: none;
    max-height: 0 !important;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.85);
  }
  #nav ul.submenu ul.submenu li {
    overflow: hidden;
  }
  #nav ul.submenu ul.submenu li a {
    border-radius: 0;
    padding-left: 38px !important;
    transition: padding-left 0.25s cubic-bezier(0, 0.7, 0, 1);
    color: #333;
  }
  #nav ul.submenu ul.submenu li a:hover {
    padding-left: 43px !important;
  }
  #nav li {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    height: 100%;
    width: auto;
    min-width: auto;
    position: relative;
    border: 0;
    overflow: visible;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }
  #nav li:first-child {
    margin-left: 0;
    border-left: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
  }
  #nav li.last {
    border-right: 0;
  }
  #nav li.right {
    float: right;
    margin-right: 0;
    border-right: 0;
  }
  #nav li.onmobile {
    display: none;
  }
  #nav li:hover {
    z-index: 11;
  }
  #nav a, #nav span.submenu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 10px;
    width: 100%;
    height: 100%;
    border: 0;
    color: #fff;
    font-weight: normal;
    text-align: center;
    transition: background-color 0.2s linear 0s;
    cursor: pointer;
  }
  #nav span.submenu:after, #nav a.submenu:after {
    display: none;
  }
  #nav a:hover, #nav a.is-active, #nav li:hover span.submenu, #nav li:hover a.submenu, #nav li:hover a.submenu, #nav ul.submenu a:hover, #nav span.submenu.is-active, #nav a.submenu.is-active {
    background-color: #0e5686;
  }
  #nav li:hover a.submenu, #nav li:hover span.submenu, #nav li:hover a.submenu, #nav span.submenu.is-active.hover, #nav a.submenu.is-active.hover {
    border-radius: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  #nav li:hover ul.submenu, #nav ul.submenu.is-active {
    padding: 0;
    left: 0;
    max-height: 470px;
    opacity: 1;
  }
  #nav ul.submenu li a:hover, #nav ul.submenu li a.is-active {
    background-color: #0c4a73;
  }
}
/* ----------------------------------------------------------------------------
	Buttons
----------------------------------------------------------------------------- */
@keyframes btnProcessing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cta-btn {
  display: inline-block;
  padding: 10px 0 8px 0;
  color: #fff;
  background-color: #555555;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 220px;
  line-height: 1.35;
  font-size: 21px;
  font-weight: normal;
  font-family: "Fjalla One", Impact, Verdana;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
  overflow: hidden;
  transition: background-color 0.1s ease-in-out 0.05s;
  border-bottom: 0;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.cta-btn:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #3c3c3c 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.3s, opacity 0.5s;
}
.cta-btn:active:after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}
.cta-btn[data-pid] {
  position: relative;
  transition: padding-left 0.3s, opacity 0.5s;
}
.cta-btn[data-pid].btn-processing:before {
  display: inline-block;
  margin-right: 7px;
  content: "";
  top: 50%;
  left: 0.7rem;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  border-radius: 20px;
  border: 3px solid #fff;
  border-top-color: black;
  border-left-color: black;
  opacity: 0.5;
  animation: btnProcessing 1s linear infinite;
}
.cta-btn.disabled {
  background: #ddd !important;
  pointer-events: none;
  color: #616161 !important;
}

.cta-btn.fancy {
  background: #555555;
  background-image: linear-gradient(#555555, #434343);
}

.cta-btn:hover,
.cta-btn:active {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #3f3f3f;
  border: 0;
}

.cta-btn.big {
  padding: 13px 0 10px 0;
}

.cta-btn.big.outline {
  padding: 12px 0 9px 0;
}

.cta-btn.small {
  padding: 9px 0 7px 0;
  font-size: 18px;
}

.cta-btn.small.fancy {
  line-height: 41px;
}

.cta-btn.small.outline {
  padding: 8px 0 6px 0;
}

.cta-btn.dark {
  color: #fff;
  background: #555555;
}

.cta-btn.orange {
  color: #fff;
  background: #ff6633;
}

.cta-btn.yellow {
  color: #333;
  background: #ffc107;
}

.cta-btn.green {
  color: #fff;
  background: #5cb865;
}

.cta-btn.red {
  color: #fff;
  background: #f44336;
}

.cta-btn.blue {
  color: #fff;
  background: #044fba;
}

.cta-btn[class~=outline] {
  border: 1px solid #555555;
}

.cta-btn.outline {
  color: #555555;
  background: transparent;
  border-color: #555555;
}

.cta-btn.dark.outline {
  color: #555555;
  background: transparent;
  border-color: #555555;
}

.cta-btn.orange.outline {
  color: #ff6633;
  background: transparent;
  border-color: #ff6633;
}

.cta-btn.green.outline {
  color: #5cb865;
  background: transparent;
  border-color: #5cb865;
}

.cta-btn.red.outline {
  color: #f44336;
  background: transparent;
  border-color: #f44336;
}

.cta-btn.yellow.outline {
  color: #ffc107;
  background: transparent;
  border-color: #ffc107;
}

.cta-btn.blue.outline {
  color: #044fba;
  background: transparent;
  border-color: #044fba;
}

.cta-btn.pill {
  border-radius: 100px;
}

.cta-btn.dark.fancy {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  background: #555555;
  background-image: linear-gradient(#555555, #434343);
}

.cta-btn.dark:hover,
.cta-btn.dark:active {
  background: #3f3f3f;
}

.cta-btn.outline:hover,
.cta-btn.outline:active,
.cta-btn.dark.outline:hover,
.cta-btn.dark.outline:active {
  background: rgba(85, 85, 85, 0.1);
}

.cta-btn.orange.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  background: #ff6633;
  background-image: linear-gradient(#ff6633, #ff4b0f);
}

.cta-btn.orange:hover,
.cta-btn.orange:active {
  background: #ff4508;
}

.cta-btn.orange.outline:hover,
.cta-btn.orange.outline:active {
  background: rgba(255, 102, 51, 0.1);
}

.cta-btn.green.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #5cb865;
  background-image: linear-gradient(#5cb865, #49a752);
  color: #fff;
}

.cta-btn.green:hover,
.cta-btn.green:active {
  background: #47a250;
}

.cta-btn.green.outline:hover,
.cta-btn.green.outline:active {
  background: rgba(92, 184, 101, 0.1);
}

.cta-btn.red.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #f44336;
  background-image: linear-gradient(#f44336, #f22314);
}

.cta-btn.red:hover,
.cta-btn.red:active {
  background: #f11d0d;
}

.cta-btn.red.outline:hover,
.cta-btn.red.outline:active {
  background: rgba(244, 67, 54, 0.1);
}

.cta-btn.blue.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #044fba;
  background-image: linear-gradient(#044fba, #034097);
}

.cta-btn.blue:hover,
.cta-btn.blue:active {
  background: #033d90;
}

.cta-btn.blue.outline:hover,
.cta-btn.blue.outline:active {
  background: rgba(4, 79, 186, 0.1);
}

.cta-btn.yellow.fancy {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background: #ffc107;
  background-image: linear-gradient(#ffce3a, #edb100);
}

.cta-btn.yellow:hover,
.cta-btn.yellow:active {
  background: #ffca2b;
}

.cta-btn.yellow.outline:hover,
.cta-btn.yellow.outline:active {
  background: rgba(255, 193, 7, 0.1);
}

.btn:hover {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
}
.btn.add-to-cart-btn {
  position: relative;
  transition: padding-left 0.3s, opacity 0.5s;
  font-weight: 600;
}
.btn.add-to-cart-btn.processing, .btn.add-to-cart-btn.btn-processing {
  padding-left: 2.5rem;
}
.btn.add-to-cart-btn.processing:before, .btn.add-to-cart-btn.btn-processing:before {
  display: block;
  position: absolute;
  content: "";
  top: 50%;
  left: 0.7rem;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  border-radius: 20px;
  border: 3px solid #fff;
  border-top-color: black;
  opacity: 0.5;
  animation: btnProcessing 1s linear infinite;
}
.btn.add-to-cart-btn.processing:active, .btn.add-to-cart-btn.processing:focus, .btn.add-to-cart-btn.btn-processing:active, .btn.add-to-cart-btn.btn-processing:focus {
  box-shadow: none;
}
.btn.add-to-cart-btn.view-cart-btn {
  background-color: #1fbf66;
  border-color: #1fbf66;
}
.btn.add-to-cart-btn.view-cart-btn:before {
  display: inline-block;
  margin-right: 5px;
  width: inherit;
  height: inherit;
  content: "\e8cc";
  color: inherit;
  font-family: "Material Icons Outlined";
  font-weight: 400;
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
.btn.btn-phone, .btn.btn-email {
  font-weight: 600;
  min-width: 200px;
  background-color: #044fba;
  border: 3px solid #044fba;
  color: #fff;
}
.btn.btn-email {
  background-color: #fff;
  border: 3px solid #044fba;
  color: #044fba;
}
.btn font {
  vertical-align: baseline !important;
  pointer-events: none;
}

.btn-primary {
  background-color: #044fba;
  border-color: #044fba;
}
.btn-primary.btn-cta {
  font-weight: 600;
  min-width: 200px;
}
.btn-primary.btn-cta.btn-lg {
  min-width: 300px;
}
.btn-primary:hover {
  background-color: #055ad3;
  border-color: #0564ec;
}

.btn-outline-primary {
  border-color: #044fba;
  color: #044fba;
}
.btn-outline-primary:hover {
  background-color: #055ad3;
  border-color: #0564ec;
}

.btn-secondary {
  background-color: #044fba;
  border-color: #044fba;
}
.btn-secondary.btn-cta {
  font-weight: 600;
  min-width: 230px;
}
.btn-secondary.btn-cta-sm {
  min-width: 160px;
}
.btn-secondary:hover {
  background-color: #055ad3;
  border-color: #0564ec;
}

.btn-outline-secondary {
  border-color: #044fba;
  color: #044fba;
}
.btn-outline-secondary:hover {
  background-color: #055ad3;
  border-color: #0564ec;
}

.btn-danger {
  background-color: #880303;
  border-color: #880303;
}

/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
}

body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #444;
}

.wrap {
  padding: 30px 0;
}

.container-fluid.but-fixed {
  max-width: 1350px;
}

.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  background-color: #0a4063;
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: 300;
}
.footer a,
.footer h3,
.footer h4 {
  color: #ffffff;
}
.footer .social a {
  border: 0;
}

/* --------- asc styles --------- */
#header-wrap {
  background-color: #3fbce1;
}

#header {
  float: none;
}
#header a.logo {
  float: left;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
  width: 80%;
  max-width: 100px;
}
#header a.logo img {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  height: auto;
  max-width: 100%;
}
#header a.logo span.big,
#header a.logo span.small {
  display: inline;
  font-size: 1.5em;
  font-weight: 500;
  color: #555;
  line-height: 1em;
  vertical-align: middle;
}
#header a.logo span.big {
  font-weight: 600;
  font-family: var(--font-family);
}
#header a.logo span.small {
  display: none;
  margin-top: 0;
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: normal;
}
#header #support {
  display: none;
  float: right;
  margin: 0 0 0 0;
  width: auto;
}
#header #support p {
  float: right;
  margin: 2px 12px 0 0;
}
#header #support p strong {
  font-size: 16px;
}
#header #support a.login {
  float: right;
  margin: 0 0 0 0;
  padding: 0 12px 2px 12px;
  font-weight: normal;
  color: #fff;
  background: #780019;
  text-decoration: none;
  border-radius: 0 0 4px 4px;
  border: 0;
}
#header #support a.login:before {
  display: inline-block;
  margin-right: 5px;
  width: inherit;
  height: inherit;
  content: "\ea77";
  color: inherit;
  font-family: "Material Icons Round";
  font-weight: normal;
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#header #support a.login:hover {
  background: #9e0021;
}
#header #support a.cart {
  float: right;
  margin: 0 0 0 0;
  padding: 0 12px 2px 12px;
  font-weight: normal;
  color: #fff;
  background: #780019;
  text-decoration: none;
  border-radius: 0 0 4px 4px;
  border: 0;
}
#header #support a.cart:before {
  display: inline-block;
  margin-right: 5px;
  width: inherit;
  height: inherit;
  content: "\e8cc";
  color: inherit;
  font-family: "Material Icons Outlined";
  font-weight: normal;
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#header #support a.cart:hover {
  background: #9e0021;
}
#header #support span.phone {
  display: none;
  padding-right: 8px;
  border-right: 1px dotted #333;
}
#header #support a.livechat {
  float: right;
  margin: 0 0 0 10px;
  font-weight: normal;
  color: #555;
}
#header #support a.livechat:before {
  display: inline-block;
  margin-right: 5px;
  width: inherit;
  height: inherit;
  content: "\e830";
  color: inherit;
  font-family: "icons";
  font-weight: normal;
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#header #support #language {
  margin-top: 20px;
  float: right;
  clear: right;
  width: 170px;
}
#header .main-phone .icon-phone:before {
  margin-right: 4px;
  font-size: 1.3em;
  transform: rotate(-100deg);
}

/* ----------------------------------------------------------------------------
    Blogs styles
----------------------------------------------------------------------------- */
.post {
  padding: 20px 0;
  border-bottom: 1px solid #dee2e6;
  overflow: hidden;
}
.post p:last-child {
  margin-bottom: 0;
}
.post .post-img {
  max-width: 200px;
}
.post .post-details {
  overflow: hidden;
}
.post .published-date {
  opacity: 0.7;
  font-size: 0.9rem;
}
.post .more-link {
  display: none;
}
.post .more-link:hover {
  margin-bottom: -1px;
}

.screen-reader-text {
  display: none;
}

figure {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  max-width: 100%;
}
figure img {
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0.25rem;
  border-radius: 0.25rem;
  border: 1px solid #dee2e6;
}
figure figcaption {
  margin-top: 1rem;
}
figure.alignright {
  float: right;
  max-width: 40%;
  margin-left: 1.5rem;
}
figure.alignleft {
  float: left;
  max-width: 40%;
  margin-right: 1.5rem;
}

/* ----------------------------------------------------------------------------
    global styles
----------------------------------------------------------------------------- */
.faded-blue-bg {
  position: relative;
}
.faded-blue-bg:before {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.9;
  background-color: #0a4063;
}

.light-blue-bg {
  position: relative;
}
.light-blue-bg:before {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.1;
  background-color: #3fbce1;
}

#support-wrap {
  border-top: 1px solid #fff;
  background-color: #0a4063;
}
#support-wrap a {
  padding: 3px 0 3px 0;
  color: #fff;
  background-color: #780019;
  border: 0;
}
#support-wrap a:first-child {
  border-right: 1px solid #fff;
}
#support-wrap a:hover {
  background-color: #92001e;
}
#support-wrap a.login:before, #support-wrap a.cart:before {
  display: inline-block;
  margin-right: 5px;
  color: inherit;
  content: "\ea77";
  font-family: "Material Icons Round";
  font-weight: normal;
  font-size: 105%;
  vertical-align: top;
}
#support-wrap a.cart:before {
  content: "\e8cc";
  font-family: "Material Icons Outlined";
}

#banner-wrap {
  background: transparent url(/images/banner-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap h1,
#banner-wrap h2,
#banner-wrap h3 {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}
#banner-wrap.faded-bg {
  position: relative;
}
#banner-wrap.faded-bg h1,
#banner-wrap.faded-bg h2,
#banner-wrap.faded-bg h3 {
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.5);
}
#banner-wrap.faded-bg:before {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.85;
  background-color: #fff;
}
#banner-wrap.about-us, #banner-wrap.contact-us, #banner-wrap.work-with-us {
  background: transparent url(/images/banner-plans-bg-sm.jpg) no-repeat 50% 50%/cover;
  position: relative;
}
#banner-wrap.about-us:after, #banner-wrap.contact-us:after, #banner-wrap.work-with-us:after {
  content: "";
  position: absolute;
  bottom: 0;
  background: #fff;
  width: 100%;
  height: 50px;
  clip-path: polygon(50% 100%, 100% 0, 100% 100%, 0 100%, 0 0);
}
#banner-wrap.plans-bg {
  background: transparent url(/images/banner-plans-bg-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.spanish, #banner-wrap.pipes-bg {
  background: transparent url(/images/banner-pipes-bg-sm.jpg) no-repeat 50% 20%/cover;
}

#phone-wrap {
  background-color: #1fbf66;
  color: #00532b;
}
#phone-wrap .open-phone {
  background-color: #015f32;
  color: #e3ffba;
  position: relative;
}
#phone-wrap .open-phone::before, #phone-wrap .open-phone::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 22px solid transparent;
  border-left: 25px solid #1fbf66;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
#phone-wrap .open-phone::after {
  left: auto;
  right: 0;
  border: 22px solid transparent;
  border-right: 25px solid #1fbf66;
}

#learn-more-wrap {
  background: transparent url(/images/banner-plans-bg-sm.jpg) no-repeat 50% 50%/cover;
}

#testimonial-wrap {
  background: transparent url(/images/testimonial-bg-sm.jpg) no-repeat 50% 50%/cover;
}

.card.border-3 {
  border-width: 3px;
}
.card.border-theme-primary {
  border-color: #0a4063;
}
.card.border-theme-secondary {
  border-color: #0a4063;
}
.card.review {
  padding-top: 20px;
  border-color: #044fba;
  position: relative;
}
.card.review:before {
  margin: 0 auto;
  display: block;
  width: 60px;
  height: 60px;
  content: "”";
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 60px;
  line-height: 1.4;
  color: #0a4063;
  background-color: #fff;
  font-weight: 700;
  text-align: center;
  border: 3px solid #044fba;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}
.card.about-us {
  border: 0;
  border-top: 5px solid #3fbce1;
  background-color: #0a4063;
}
.card.about-us .img-fluid {
  max-width: 220px;
}
.card.contact-us {
  border-color: #044fba;
}
.card.contact-us .img-fluid {
  max-width: 220px;
}
.card.contact-us a.phone-link {
  border-top: 3px solid #0a4063;
  color: #0a4063;
}
.card.course-type {
  border-color: #0a4063;
  margin-top: 50px;
}
.card.course-type .icon {
  margin-top: -43px;
  margin-left: 1.25rem;
  padding: 15px;
  width: 86px;
  height: 86px;
  border: 3px solid #0a4063;
  color: #444;
  background-color: #fff;
  border-radius: 0.3rem;
  text-align: center;
}
.card.course-box {
  z-index: 0;
}
.card.course-box .course-img {
  opacity: 0.75;
  transition: opacity 0.15s ease-in-out;
}
.card.course-box .ribbon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  right: -3px;
}
.card.course-box .ribbon:before, .card.course-box .ribbon:after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  height: 3px;
  width: 3px;
  background-color: black;
}
.card.course-box .ribbon:before {
  top: 0;
  left: 0;
  border-top-left-radius: 2px;
}
.card.course-box .ribbon:after {
  bottom: 0;
  right: 0;
  border-bottom-right-radius: 2px;
}
.card.course-box .ribbon span {
  position: absolute;
  display: block;
  width: 120px;
  padding: 2px 0;
  background-color: #0a4063;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  top: 16px;
  left: -17px;
  transform: rotate(45deg);
}
.card.course-box.border-3 .ribbon {
  top: -6px;
  right: -6px;
}
.card.course-box.vertical .course-img {
  padding: 0.25rem;
  background-color: #fff !important;
  border: 1px solid #dee2e6;
}
.card.course-box.vertical .course-img .card-img-top {
  border-radius: 0;
}
.card.course-box:hover .course-img {
  opacity: 1;
}

.staff {
  padding: 0 0.75rem;
}
.staff .img-fluid {
  max-width: 150px;
}

.line-seperator {
  position: relative;
  margin-bottom: 2.75rem;
  background: #0a4063;
  width: 100%;
  height: 20px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.boxed-hover {
  transition: box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out;
}
.boxed-hover:hover {
  box-shadow: 0 3px 10px rgba(27, 141, 222, 0.2);
  transform: translateY(-1px);
}

.form-msg p:last-child {
  margin-bottom: 0;
}

.mobile-phone {
  position: fixed;
  width: 50px;
  height: 50px;
  background-color: #008000;
  color: #fff !important;
  text-align: center;
  left: 20px;
  bottom: 20px;
  border-radius: 50px;
}
.mobile-phone .icon-phone {
  font-size: 1.75rem;
  line-height: 48px;
}
.mobile-phone .icon-phone:before {
  transform: rotate(-90deg);
}

a.card:hover {
  border-style: solid;
  border-color: #044fba !important;
}
a.card:hover .get-started {
  color: #044fba;
}

.notice-wrap {
  margin: 20px;
  background-color: #ff903d;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  padding: 12px 0;
  position: relative;
  text-align: center;
  z-index: 60;
}
.notice-wrap p {
  color: #fff;
  margin: 0 16px;
  text-shadow: 0 0px 1px rgba(0, 0, 0, 0.35);
}

.cfix {
  clear: both;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
@media (min-width: 576px) {
  .card.course-box.horizontal h3 {
    margin-right: 20px;
  }
}
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  #header-wrap #header #support {
    display: block;
  }
  #header-wrap #header #support span.phone {
    display: inline-block;
  }
  #phone-wrap {
    font-size: 1.1rem;
  }
}
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
}
@media (min-width: 992px) {
  #header a.logo {
    max-width: 120px;
  }
  #header #support {
    display: block;
  }
  #header #support span.phone {
    display: inline-block;
  }
  #phone-wrap .open-phone {
    font-size: 1.4rem;
    border-radius: 20px;
  }
  #phone-wrap .open-phone:before {
    left: 17px;
  }
  #phone-wrap .open-phone:after {
    right: 17px;
  }
  #phone-wrap .open-blurb {
    position: relative;
  }
  #phone-wrap .open-blurb:after {
    position: absolute;
    content: "";
    width: 20px;
    height: 28px;
    background-color: #1fbf66;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  #phone-wrap .open-days:after {
    position: absolute;
    content: "";
    width: 20px;
    height: 28px;
    background-color: #1fbf66;
    left: -20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  #banner-wrap {
    background: transparent url(/images/banner-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap h1 {
    font-size: 2.5rem;
  }
  #banner-wrap.about-us, #banner-wrap.contact-us, #banner-wrap.work-with-us, #banner-wrap.plans-bg {
    background: transparent url(/images/banner-plans-bg.jpg) no-repeat 50% 50%/cover;
  }
  #banner-wrap.spanish, #banner-wrap.pipes-bg {
    background: transparent url(/images/banner-pipes-bg.jpg) no-repeat 50% 50%/cover;
  }
  #learn-more-wrap {
    background: transparent url(/images/banner-plans-bg.jpg) no-repeat 50% 50%/cover;
  }
  #testimonial-wrap {
    background: transparent url(/images/testimonial-bg.jpg) no-repeat 50% 50%/cover;
  }
  .card.course-box.vertical .course-img {
    max-width: 250px !important;
  }
  .post {
    padding: 30px 0;
  }
}
@media (min-width: 1200px) {
  #banner-wrap h1 {
    font-size: 3rem;
  }
}